import React, { FC } from 'react';
import { GoBack } from '..';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import './Page.scss';

export interface PageProps {
  children?: React.ReactNode;
  /**
   * Go back title
   */
  goBackTitle?: string;
  /**
   * Go back URL
   */
  goBackURL?: string;
  /**
   * Go back show allways
   */
  goBackShowAllways?: boolean;
  /**
   * Set to true to show a spinner while page is loading.
   */
  loading?: boolean;
  /**
   * Sub title h2
   */
  subTitle?: string;
  /**
   * Main title h1
   */
  title?: string;
}

export const Page: FC<PageProps> = (props) => {
  return (
    <div className="page">
      <GoBack showAlways={props.goBackShowAllways ?? false} to={props.goBackURL} text={props.goBackTitle} />
      {!props?.loading ? (
        <>
          {props.title && <h1 className="data-hj-suppress" dangerouslySetInnerHTML={{ __html: props.title }}></h1>}
          {props.subTitle && <h2 className="data-hj-suppress">{props.subTitle}</h2>}
          {props?.children}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};
