import { useEffect } from 'react';

export const useUxSignalScript = (ready: boolean) => {
  useEffect(() => {
    const script = document.createElement('script');
    if (ready) {
      script.async = true;
      script.src = 'https://widget.uxsignals.com/embed.js';
      document.body.appendChild(script);
    }

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [ready]);
};
