import React, { FC, Suspense, useContext } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Applications } from './pages/Applications/ApplicationsRouter';
import { AppContext } from './context/app-context';
import { UserStatus } from './models';
import { LoadingSpinner } from './components/Shared/LoadingSpinner/LoadingSpinner';

const Forms = React.lazy(() => import('./pages/Forms/FormsRouter'));
const BankAccounts = React.lazy(() => import('./pages/BankAccounts/BankAccountsRouter'));
const CaseManagement = React.lazy(() => import ('./pages/CaseManagement/CaseManagementRouter'));
const Documents = React.lazy(() => import ('./pages/Documents/DocumentsRouter'));
const GeneralApplicationForms = React.lazy(() => import ('./pages/GeneralApplications/GeneralApplicationsRouter'));
const ReleaseOfCollateralsApplication = React.lazy(() => import ('./pages/Forms/ReleaseOfCollateral/ReleaseOfCollateralsApplication'));

const AppRouter = () => {
  const {state} = useContext(AppContext);

  
  if (state.user.status === UserStatus.LOGGED_IN)
  return (
    <Layout>
      <Routes>
        <Route path='/' element={<Navigate replace to="/bankaccounts" />} />
        <Route path='/bankaccounts/*' element={<Suspense fallback={<LoadingSpinner />}><BankAccounts /></Suspense>} />
        <Route path='/casemanagement/*' element={<Suspense fallback={<LoadingSpinner />}><CaseManagement /></Suspense>} />
        <Route path='/documents/*' element={<Suspense fallback={<LoadingSpinner />}><Documents /></Suspense>} />
        <Route path='/forms/*' element={<Suspense fallback={<LoadingSpinner />}><Forms /></Suspense>} />
        <Route path='/applications/*' element={<Suspense fallback={<LoadingSpinner />}><Applications /></Suspense>} />
        <Route path='/generalapplications/*' element={<Suspense fallback={<LoadingSpinner />}><GeneralApplicationForms /></Suspense>} />
        <Route path='/ReleaseOfCollateralsApplication/*' element={<Suspense fallback={<LoadingSpinner />}><ReleaseOfCollateralsApplication /></Suspense>} />
      </Routes>
    </Layout>
  );

  return (<div />); 
}

export const App: FC = () => {
  return <AppRouter />
}
