export const formLocalization = {
  deleteDraftTitle: {
    en: 'Are you sure you want to delete the draft?',
    nb: 'Er du sikker på at du vil slette utkastet?',
    nn: 'Er du sikker på at du vil slette utkastet?',
  },
  deleteDraftConfirmText: {
    en: 'Yes',
    nb: 'Ja',
    nn: 'Ja',
  },
  deleteDraftCancelText: {
    en: 'Cancel',
    nb: 'Avbryt',
    nn: 'Avbryt',
  },
  previousLabel: {
    en: 'Previous',
    nb: 'Forrige',
    nn: 'Førre',
  },
  nextLabel: {
    en: 'Next',
    nb: 'Neste',
    nn: 'Neste',
  },
  submitLabel: {
    en: 'Submit',
    nb: 'Send inn',
    nn: 'Send inn',
  },
  saveAndClose: {
    en: 'Save and close',
    nb: 'Lagre og lukk',
    nn: 'Lagre og lukk',
  },
  deleteDraft: {
    en: 'Delete draft',
    nb: 'Slett utkast',
    nn: 'Slett utkast',
  },
  delete: {
    en: 'Delete',
    nb: 'Slett',
    nn: 'Slett',
  },
  yes: {
    en: 'Yes',
    nb: 'Ja',
    nn: 'Ja',
  },
  no: {
    en: 'No',
    nb: 'Nei',
    nn: 'Nei',
  },
  noFilesUploaded: {
    en: 'No attachments.',
    nb: 'Ingen vedlegg.',
    nn: 'Ingen vedlegg.',
  },
  requiredFieldErrorMessage: {
    en: 'This field is required.',
    nb: 'Dette feltet er påkrevd.',
    nn: 'Dette feltet er påkrevd.',
  },
} as const;
