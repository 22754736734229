export const localizationBankAccountList = {
  inactiveEngagements: {
    en: 'Inactive accounts',
    nb: 'Inaktive kontoer',
    nn: 'Inaktive kontoar',
  },
  errorHeader: {
    en: 'Something went wrong',
    nb: 'Noe gikk galt',
    nn: 'Noko gjekk galt',
  },
  errorText: {
    en: 'The page could not open. If the problem persists, please contact',
    nb: 'Siden kunne ikke åpnes. Om problemet vedvarer, vennligst meld fra til',
    nn: 'Sida kunne ikkje opnast. Om problemet held fram, ver vennleg og meld frå til',
  },
  errorRefreshPage: {
    en: 'Click here to reload the page',
    nb: 'Klikk her for å laste siden på nytt',
    nn: 'Klikk her for å lasta sida på nytt',
  },
} as const;
